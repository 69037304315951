<template>
  <div class="main-body">
    <div class="page-attention">
      <p>
        应用管理、版本管理；控制不同的应用版本，如：安卓APP、苹果APP、H5、小程序、微信公众号、桌面应用。
      </p>
    </div>
    <a-table class="doc-table" :dataSource="dataSource" :columns="columns" :pagination="false">
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'name'">
          <a-avatar :src="record.pay_icon" />
          <a-button type="text">{{ record.name }}</a-button>
        </template>
        <template v-if="column.key === 'vsersion_name'">
          {{ record.vsersion_name }}【{{ record.version }}】
        </template>
        <template v-if="column.key === 'status'">
          <span v-if="record.status == 1" style="color: #b7eb8f"> 已启用 </span>
          <span v-else style="color: #ffe58f">未启用</span>
        </template>
        <template v-if="column.key === 'action'">
          <router-link :to="record.path">
            <a-button type="primary" size="small">配置</a-button>
          </router-link>
        </template>
      </template>
    </a-table>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted } from "vue";
import * as Api from "@/views/payment/api";

export default defineComponent({
  setup() {
    const dataSource = ref();
    const onLoadData = () => {
      Api.payment_mode_list().then((res) => {
        dataSource.value = res.data;
      });
    };

    // 初始化获取左侧导航
    onMounted(() => {
      onLoadData();
    });

    return {
      dataSource,
      columns: [
        {
          title: "支付方式名称",
          dataIndex: "name",
          key: "name",
          width: "20%",
        },
        {
          title: "描述",
          dataIndex: "description",
          key: "description",
          width: "60%",
        },
        {
          title: "状态",
          dataIndex: "status",
          key: "status",
          width: "10%",
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          width: "10%",
        },
      ],
    };
  },
});
</script>